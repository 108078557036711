    <template>


            <div class="mt-6">
                        <div class="max-w-4xl px-10 py-6 mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800">
                            <div class="flex items-center justify-between"><span class="font-light text-gray-600 dark:text-gray-200">Dec 23,
                                    2018</span><a href="#"
                                    class="px-2 py-1 font-bold text-gray-100 bg-gray-600 rounded hover:bg-gray-500">Django</a>
                            </div>
                            <div class="mt-2"><a href="#" class="text-2xl font-bold text-gray-700 hover:underline dark:text-gray-100">{{ blog.title }}</a>
                                <p class="mt-2 text-gray-600 dark:text-gray-400">{{ truncatedContent }}</p>
                            </div>
                            <div class="flex items-center justify-between mt-4">
                                <router-link :to="`/blog/${blog.id}`" class="text-blue-500 hover:text-blue-700 transition-colors duration-300"> Read more </router-link>
                                <div><a href="#" class="flex items-center"><img
                                            src="https://images.unsplash.com/photo-1500757810556-5d600d9b737d?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=735&amp;q=80"
                                            alt="avatar" class="hidden object-cover w-10 h-10 mx-4 rounded-full sm:block">
                                        <h1 class="font-bold text-gray-700 hover:underline dark:text-gray-300">{{ blog.author }}</h1>
                                    </a></div>
                            </div>
                        </div>
            </div>
    </template>

        <script>
        export default {
            name: 'BlogSummary',
            props: {
            blog: {
                type: Object,
                required: true,
            },
            },
            computed: {
            // Compute a truncated version of the blog content
            truncatedContent() {
                const maxLength = 150;
                return this.blog.content.length > maxLength
                ? `${this.blog.content.slice(0, maxLength)}...`
                : this.blog.content;
            },
            },
            mounted() {
            console.log('BlogSummary component mounted');
            console.log('Blog data:', this.blog);
            },
        };
        </script>
        