// src/main.js
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './firebase';
import './assets/tailwind.css';
import FontAwesomeIcon from './plugins/font-awesome';
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import sw from './locales/sw.json';
import de from './locales/de.json';
import ar from './locales/ar.json';
import zh from './locales/zh.json';
import fr from './locales/fr.json';
import es from './locales/es.json'
import hi from './locales/hi.json'
import './registerServiceWorker'


const app = createApp(App);

const messages = {en,sw,de,ar,zh,fr,es,hi,
};

const i18n = createI18n({
    locale: 'en', // default locale
    legacy: false, // Enables Composition API mode
    globalInjection: true, // Allows global use of $t and other i18n methods
    fallbackLocale: 'en',
    messages,
});

app.component('font-awesome-icon', FontAwesomeIcon);

app
    .use(router)
    .use(store)
    .use(i18n)
    .mount('#app');
