    <template>
        <div class="min-h-screen bg-gray-100 dark:bg-gray-800 transition-colors duration-300 p-4">
        <div v-if="loading" class="loader border-4 border-gray-300 border-t-4 border-t-blue-500 rounded-full w-10 h-10 animate-spin mx-auto"></div>
        
        <div v-else class="container mx-auto grid grid-cols-1 lg:grid-cols-3 gap-6">
            <!-- Left Column: Blogs -->
            <div class="lg:col-span-2">
            <BlogSummary v-for="blog in blogs" :key="blog.id" :blog="blog" />
            </div>
            
            <!-- Right Column: Authors, Categories, Recent Posts -->
            <div class="hidden lg:block">
            <div class="px-8">
                <h1 class="mb-4 text-xl font-bold text-gray-700 dark:text-gray-300">Authors</h1>
                <div class="flex flex-col max-w-sm px-6 py-4 mx-auto bg-white dark:bg-gray-700 rounded-lg shadow-md">
                <ul class="-mx-4">
                    <li class="flex items-center">
                    <img
                        src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=731&amp;q=80"
                        alt="avatar" class="object-cover w-10 h-10 mx-4 rounded-full">
                    <p>
                        <a href="#" class="mx-1 font-bold text-gray-700 dark:text-gray-300 hover:underline">Jovic Biralo</a>
                        <span class="text-sm font-light text-gray-700 dark:text-gray-400">Created 30 Posts</span>
                    </p>
                    </li>
                    <li class="flex items-center mt-6">
                    <img
                        src="https://images.unsplash.com/photo-1464863979621-258859e62245?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=333&amp;q=80"
                        alt="avatar" class="object-cover w-10 h-10 mx-4 rounded-full">
                    <p>
                        <a href="#" class="mx-1 font-bold text-gray-700 dark:text-gray-300 hover:underline">Jane Doe</a>
                        <span class="text-sm font-light text-gray-700 dark:text-gray-400">Created 52 Posts</span>
                    </p>
                    </li>
                    <li class="flex items-center mt-6">
                    <img
                        src="https://images.unsplash.com/photo-1531251445707-1f000e1e87d0?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=281&amp;q=80"
                        alt="avatar" class="object-cover w-10 h-10 mx-4 rounded-full">
                    <p>
                        <a href="#" class="mx-1 font-bold text-gray-700 dark:text-gray-300 hover:underline">Lisa Way</a>
                        <span class="text-sm font-light text-gray-700 dark:text-gray-400">Created 73 Posts</span>
                    </p>
                    </li>
                    <li class="flex items-center mt-6">
                    <img
                        src="https://images.unsplash.com/photo-1500757810556-5d600d9b737d?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=735&amp;q=80"
                        alt="avatar" class="object-cover w-10 h-10 mx-4 rounded-full">
                    <p>
                        <a href="#" class="mx-1 font-bold text-gray-700 dark:text-gray-300 hover:underline">Steve Matt</a>
                        <span class="text-sm font-light text-gray-700 dark:text-gray-400">Created 245 Posts</span>
                    </p>
                    </li>
                    <li class="flex items-center mt-6">
                    <img
                        src="https://images.unsplash.com/photo-1502980426475-b83966705988?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=373&amp;q=80"
                        alt="avatar" class="object-cover w-10 h-10 mx-4 rounded-full">
                    <p>
                        <a href="#" class="mx-1 font-bold text-gray-700 dark:text-gray-300 hover:underline">Khatab Wedaa</a>
                        <span class="text-sm font-light text-gray-700 dark:text-gray-400">Created 332 Posts</span>
                    </p>
                    </li>
                </ul>
                </div>
            </div>
            
            <div class="px-8 mt-10">
                <h1 class="mb-4 text-xl font-bold text-gray-700 dark:text-gray-300">Categories</h1>
                <div class="flex flex-col max-w-sm px-4 py-6 mx-auto bg-white dark:bg-gray-700 rounded-lg shadow-md">
                <ul>
                    <li>
                    <a href="#" class="mx-1 font-bold text-gray-700 dark:text-gray-300 hover:text-gray-600 hover:underline">- AWS</a>
                    </li>
                    <li class="mt-2">
                    <a href="#" class="mx-1 font-bold text-gray-700 dark:text-gray-300 hover:text-gray-600 hover:underline">- Laravel</a>
                    </li>
                    <li class="mt-2">
                    <a href="#" class="mx-1 font-bold text-gray-700 dark:text-gray-300 hover:text-gray-600 hover:underline">- Vue</a>
                    </li>
                    <li class="mt-2">
                    <a href="#" class="mx-1 font-bold text-gray-700 dark:text-gray-300 hover:text-gray-600 hover:underline">- Design</a>
                    </li>
                    <li class="flex items-center mt-2">
                    <a href="#" class="mx-1 font-bold text-gray-700 dark:text-gray-300 hover:text-gray-600 hover:underline">- Django</a>
                    </li>
                    <li class="flex items-center mt-2">
                    <a href="#" class="mx-1 font-bold text-gray-700 dark:text-gray-300 hover:text-gray-600 hover:underline">- PHP</a>
                    </li>
                </ul>
                </div>
            </div>
            
            <div class="px-8 mt-10">
                <h1 class="mb-4 text-xl font-bold text-gray-700 dark:text-gray-300">Recent Post</h1>
                <div class="flex flex-col max-w-sm px-8 py-6 mx-auto bg-white dark:bg-gray-700 rounded-lg shadow-md">
                <div class="flex items-center justify-center">
                    <a href="#" class="px-2 py-1 text-sm text-green-100 bg-gray-600 rounded hover:bg-gray-500">Laravel</a>
                </div>
                <div class="mt-4">
                    <a href="#" class="text-lg font-medium text-gray-700 dark:text-gray-300 hover:underline">Build Your New Idea with Laravel Framework.</a>
                </div>
                <div class="flex items-center justify-between mt-4">
                    <div class="flex items-center">
                    <img src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=731&amp;q=80" alt="avatar" class="object-cover w-8 h-8 rounded-full">
                    <a href="#" class="mx-3 text-sm text-gray-700 dark:text-gray-300 hover:underline">Alex John</a>
                    </div>
                    <span class="text-sm font-light text-gray-600 dark:text-gray-400">Jun 1, 2020</span>
                </div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </template>
    
        
        <script setup>
        import { ref, onMounted } from 'vue';
        import { db } from '@/firebase';  // Ensure this is the correct path to your firebase file
        import BlogSummary from '@/components/BlogSummary.vue';
        import { collection, getDocs } from 'firebase/firestore';
        
        const blogs = ref([]);
        const loading = ref(true);
        
        onMounted(async () => {
            try {
            // Fetch the blog documents from Firestore
            const querySnapshot = await getDocs(collection(db, 'blogs'));
        
            // Map the fetched documents to the blogs array
            blogs.value = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
        
            console.log('Fetched Blogs:', blogs.value);
            } catch (error) {
            console.error('Error fetching blogs:', error);
            } finally {
            loading.value = false;
            console.log('Loading complete');
            }
        });
        </script>
        