    <template>
        <header class="bg-white dark:bg-gray-900 shadow-md fixed w-full top-0 z-50">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="flex justify-between items-center py-6">
            <!-- Logo -->
            <div class="flex-shrink-0">
                <router-link to="/" class="text-2xl font-bold text-gray-900 dark:text-white leading-none hover:text-blue-500 dark:hover:text-blue-500 transition-colors">
                <span class="text-lg md:text-2xl">Jovic</span><span class="text-lg md:text-2xl text-blue-500">Biralo</span>
                </router-link>
            </div>
            <!-- Hamburger Menu -->
            <div class="md:hidden">
                <button @click="isMenuOpen = !isMenuOpen" class="text-gray-900 dark:text-white focus:outline-none">
                <svg v-if="!isMenuOpen" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
                </svg>
                <svg v-else class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
                </button>
            </div>
            <!-- Navigation and Controls -->
            <div :class="{'translate-x-0': isMenuOpen, '-translate-x-full': !isMenuOpen}" class="fixed inset-y-0 left-0 w-64 bg-white dark:bg-gray-900 transform md:relative md:translate-x-0 transition-transform duration-300 ease-in-out md:flex md:flex-row md:space-x-4 md:w-auto">
                <!-- Navigation Tabs -->
                <nav class="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 p-4 md:p-0">
                <router-link to="/" :class="['nav-link', isActive('/') ? 'active' : '']">{{ $t('header.bio') }}</router-link>
                <router-link to="/contact" :class="['nav-link', isActive('/contact') ? 'active' : '']">{{ $t('header.contact') }}</router-link>
                <router-link to="/blogs" :class="['nav-link', isActive('/blogs') ? 'active' : '']">{{ $t('header.blog') }}</router-link>
                <router-link to="/signup" :class="['nav-link', isActive('/signup') ? 'active' : '']">{{ $t('header.signUp') }}</router-link>
                </nav>
                <!-- Light/Dark Mode and Music Toggle -->
                <div class="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-4 p-4 md:p-0">
                <button @click="toggleDarkMode" class="focus:outline-none">
                    <svg v-if="isDarkMode" class="w-6 h-6 text-gray-900 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3v2m0 14v2m7-7h2m-14 0H3m16.364-7.364l-1.414 1.414M7.05 7.05L5.636 5.636M16.95 16.95l1.414 1.414M7.05 16.95l-1.414 1.414"></path>
                    </svg>
                    <svg v-else class="w-6 h-6 text-gray-900 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3.055a9.002 9.002 0 000 17.89m6.362-15.362A9 9 0 0112 21V3.055z"></path>
                    </svg>
                </button>
                <button @click="toggleMusic" class="focus:outline-none">
                    <svg v-if="isMusicPlaying" class="w-6 h-6 text-gray-900 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 9v6m4-6v6"></path>
                    </svg>
                    <svg v-else class="w-6 h-6 text-gray-900 dark:text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 4v16l12-8-12-8z"></path>
                    </svg>
                </button>
                <!-- Custom styled select -->
                <div class="relative">
                    <select v-model="selectedSong" @change="changeSong" class="appearance-none bg-transparent border dark:bg-black border-gray-300 dark:border-gray-600 text-gray-800 dark:text-white py-2 pl-3 pr-10 rounded-md focus:outline-none focus:border-blue-500 focus:ring-1 focus:ring-blue-500 focus:ring-opacity-50">
                    <option value="BobMarley">Bob Marley</option>
                    <option value="DonWilliams">Don Williams</option>
                    <option value="DollyParton">Dolly Parton</option>
                    <option value="UB40">UB40</option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800 dark:text-white">
                    <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                    </div>
                </div>
                <!-- End of custom select -->
                <!-- Language Selector Component -->
                <LanguageSelector />

                    <!-- PWA Install Button -->
                <button v-if="deferredPrompt && !isAppInstalled" @click="installApp" class="focus:outline-none text-white bg-blue-500 hover:bg-blue-700 font-bold py-2 px-4 rounded">
                    Install App
                </button>

            </div>
            </div>
            </div>
        </div>
    
        </header>
    </template>
    
    <script setup>
    import { ref, computed, onMounted } from 'vue';
    import { useStore } from 'vuex';
    import { useRoute } from 'vue-router';
    import LanguageSelector from './LanguagePrefernce.vue';
    import { useI18n } from 'vue-i18n';

    const { t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const isMenuOpen = ref(false);
    const deferredPrompt = ref(null); // Define the deferredPrompt variable
    const isAppInstalled = ref(false); // Define the isAppInstalled variable
    
    const isDarkMode = computed(() => store.state.isDarkMode);
    const isMusicPlaying = computed(() => store.state.isMusicPlaying);
    const selectedSong = computed({
        get: () => store.state.selectedSong,
        set: (value) => store.commit('setSelectedSong', value)
    });
    
    const toggleDarkMode = () => {
        store.dispatch('toggleDarkMode');
    };
    
    const toggleMusic = () => {
        store.dispatch('toggleMusic');
    };

    const changeSong = () => {
        store.dispatch('setSelectedSong', selectedSong.value);
    };
    
    // Initialize dark mode state when the component mounts
        onMounted(() => {

    store.dispatch('initializeDarkMode');

    window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    deferredPrompt.value = e;});

    window.addEventListener('appinstalled', () => {
        isAppInstalled.value = true;
    });

        if (window.matchMedia('(display-mode: standalone)').matches) {
        isAppInstalled.value = true;
    }

    });

    const installApp = async () => {
            if (deferredPrompt.value) {
        deferredPrompt.value.prompt();
        const { outcome } = await deferredPrompt.value.userChoice;
        if (outcome === 'accepted') {
        isAppInstalled.value = true;
        }
        deferredPrompt.value = null;
    }
    };

    // Function to check if the route is active
    const isActive = (path) => {
        return route.path === path;
    };

    
    </script>
    
    <style scoped>
    /* Styles for navigation links */
    .nav-link {
        padding: 8px 12px;
        font-weight: 500;
        text-decoration: none;
        color: #1a202c; /* Default text color */
        transition: color 0.3s ease, background-color 0.3s ease;
    }
    
    .nav-link:hover {
        color: #3182ce; /* Hover text color */
    }
    
    /* Styles for the active tab */
    .active {
        color: #ffffff;
        background-color: #3182ce; /* Background color for the active tab */
        border-radius: 4px;
    }
    
    .dark .nav-link {
        color: #e2e8f0; /* Text color for dark mode */
    }
    
    .dark .nav-link:hover {
        color: #f5f8fa; /* Hover text color for dark mode */
    }
    
    .dark .active {
        color: #1a202c; /* Text color for active tab in dark mode */
        background-color: #63b3ed; /* Background color for active tab in dark mode */
    }
    
    /* Transition for sliding menu */
    .transition-transform {
        transition: transform 0.3s ease-in-out;
    }
    </style>
    