<template>
    <footer class="bg-gray-600 dark:bg-gray-900 py-4  bottom-0 w-full">
        <div class="container mx-auto text-center text-gray-400">
            <p>&copy; {{ currentYear }} Jovic Biralo. All rights reserved.</p>
            <p>Brought to life by Jovic Biralo</p>
            <div class="mt-2">
                <a href="https://github.com/BIRALO256" class="text-blue-500 hover:underline">GitHub</a> |
                <a href="https://www.linkedin.com/in/jovic-biralo" class="text-blue-500 hover:underline">LinkedIn</a> |
                <a href="mailto:dearjovic@gmail.com" class="text-blue-500 hover:underline">Contact</a>
            </div>
        </div>
    </footer>
</template>

<script setup>
import { ref } from 'vue';

const getCurrentYear = () => {
    return new Date().getFullYear();
};

const currentYear = ref(getCurrentYear());
</script>

<style scoped>
footer {
    
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for separation */
}

.dark footer {
    background-color: #1a202c; /* Dark mode background color */
}

.container {
    padding: 0 16px; /* Adjust padding for container */
}

footer p, footer a {
    font-size: 14px; /* Font size for text and links */
}

footer a {
    transition: color 0.3s ease, text-decoration 0.3s ease; /* Smooth transition for links */
}
</style>
