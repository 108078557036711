  <template>
    <div class="max-w-3xl mx-auto p-6 bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-100 rounded-lg shadow-md">
      <img :src="blog.image" alt="Blog Image" class="w-full h-64 object-cover rounded-lg mb-4" />
      <h1 class="text-3xl font-bold mb-4">{{ blog.title }}</h1>
      <p class="text-gray-600 dark:text-gray-300 mb-4">{{ formattedDate }}</p>
      <div v-html="blog.content" class="prose dark:prose-invert"></div>
    </div>
  </template>

  <script>
  import { ref, onMounted, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { db } from '@/firebase';

  export default {
    setup() {
      const route = useRoute();
      const blog = ref({});

      onMounted(async () => {
        try {
          const doc = await db.collection('blogs').doc(route.params.id).get();
          if (doc.exists) {
            blog.value = doc.data();
          } else {
            console.error('No such document!');
          }
        } catch (error) {
          console.error('Error fetching blog:', error);
        }
      });

      const formattedDate = computed(() => {
        return blog.value.publishedDate?.toDate().toLocaleDateString() || 'Date not available';
      });

      return {
        blog,
        formattedDate
      };
    }
  }
  </script>
