    <template>
        <div class="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-800">
        <div class="w-full max-w-lg p-8 bg-white dark:bg-gray-800 rounded-lg shadow-lg">
            <h2 class="text-2xl font-semibold mb-6 text-center text-gray-900 dark:text-gray-100">Welcome!</h2>
            <p class="text-center text-gray-600 dark:text-gray-300">
            Thank you for signing up.
            </p>
        </div>
        </div>
    </template>
    
    <script setup>
    </script>
    
    <style scoped>
    /* Scoped styles for this page */
    </style>
    