    <template>
        <div class="py-6">
        <div class="flex bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden mx-auto max-w-sm lg:max-w-4xl">
            <div class="hidden lg:block lg:w-1/2 bg-cover" style="background-image:url('https://images.unsplash.com/photo-1546514714-df0ccc50d7bf?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=667&q=80')"></div>
            <div class="w-full p-8 lg:w-1/2">
            <h2 class="text-2xl font-semibold text-gray-700 dark:text-gray-300 text-center">{{ isSignUp ? 'Create an Account' : 'Welcome Back' }}</h2>
            <p class="text-xl text-gray-600 dark:text-gray-400 text-center">{{ isSignUp ? 'Sign up to get started!' : 'Log in to continue!' }}</p>
            
            <a v-if="!isSignUp" @click="handleGoogleSignIn" class="flex items-center justify-center mt-4 text-white bg-gray-700 dark:bg-gray-600 rounded-lg shadow-md hover:bg-gray-600 dark:hover:bg-gray-500 cursor-pointer">
                <div class="px-4 py-3">
                    <svg class="h-6 w-6" viewBox="0 0 40 40">
                        <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#FFC107"/>
                        <path d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z" fill="#FF3D00"/>
                        <path d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z" fill="#4CAF50"/>
                        <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#1976D2"/>
                    </svg>
                </div>
                <h1 class="px-4 py-3 w-5/6 text-center text-gray-600 dark:text-gray-200 font-bold">Sign in with Google</h1>
            </a>
    
            <div class="mt-4 flex items-center justify-between">
                <span class="border-b border-gray-300 dark:border-gray-600 w-1/5 lg:w-1/4"></span>
                <a href="#" class="text-xs text-center text-gray-500 dark:text-gray-400 uppercase">{{ isSignUp ? 'or login with email' : 'or sign up with email' }}</a>
                <span class="border-b border-gray-300 dark:border-gray-600 w-1/5 lg:w-1/4"></span>
            </div>
    
            <form @submit.prevent="isSignUp ? handleSignUp() : handleLogin()">
                <div class="mt-4" v-if="isSignUp">
                <label class="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">Username</label>
                <input v-model="username" class="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 focus:outline-none focus:shadow-outline border border-gray-300 dark:border-gray-600 rounded py-2 px-4 block w-full appearance-none" type="text">
                </div>
                <div class="mt-4">
                <label class="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">Email Address</label>
                <input v-model="email" class="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 focus:outline-none focus:shadow-outline border border-gray-300 dark:border-gray-600 rounded py-2 px-4 block w-full appearance-none" type="email">
                </div>
                <div class="mt-4">
                <div class="flex justify-between">
                    <label class="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">Password</label>
                    <a href="#" class="text-xs text-gray-500 dark:text-gray-400">Forgot Password?</a>
                </div>
                <input v-model="password" class="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-300 focus:outline-none focus:shadow-outline border border-gray-300 dark:border-gray-600 rounded py-2 px-4 block w-full appearance-none" type="password">
                </div>
                <div class="mt-8">
                <button type="submit" class="bg-gray-700 dark:bg-gray-600 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600 dark:hover:bg-gray-500">
                    {{ isSignUp ? 'Sign Up' : 'Login' }}
                </button>
                </div>
            </form>
    
            <div class="mt-4 flex items-center justify-between">
                <span class="border-b border-gray-300 dark:border-gray-600 w-1/5 md:w-1/4"></span>
                <a href="#" @click="toggleSignUp" class="text-xs text-gray-500 dark:text-gray-400 uppercase">{{ isSignUp ? 'or login' : 'or sign up' }}</a>
                <span class="border-b border-gray-300 dark:border-gray-600 w-1/5 md:w-1/4"></span>
            </div>
            </div>
        </div>
        </div>
    </template>
    
    <script setup>
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { auth, db } from '@/firebase';
    import { useStore } from 'vuex';
    import { signInWithEmailAndPassword, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
    import { addDoc, collection } from 'firebase/firestore';
    
    const router = useRouter();
    const email = ref('');
    const store = useStore();
    const password = ref('');
    const username = ref('');
    const isSignUp = ref(false);
    
    const handleGoogleSignIn = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            const user = result.user;
    
            // Store user in Firestore if signing in for the first time
            await addDoc(collection(db, 'users'), {
                uid: user.uid,
                username: user.displayName,
                email: user.email,
                role: 0 // Default role
            });
            // Update Vuex user state
            store.commit('setUser', user);
    
            router.push('/welcome');
        } catch (error) {
            console.error('Error with Google sign-in:', error.message);
        }
    };
    
    const handleLogin = async () => {
        try {
            await signInWithEmailAndPassword(auth, email.value, password.value);
            router.push('/welcome');
        } catch (error) {
            console.error('Error logging in:', error.message);
        }
    };
    
    const handleSignUp = async () => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
            const user = userCredential.user;
    
            // Store user in Firestore
            await addDoc(collection(db, 'users'), {
                uid: user.uid,
                username: username.value,
                email: email.value,
                role: 0 // Default role for new users
            });

              // Update Vuex user state
            store.commit('setUser', user);
    
            router.push('/welcome');
        } catch (error) {
            console.error('Error signing up:', error.message);
        }
    };
    
    const toggleSignUp = () => {
        isSignUp.value = !isSignUp.value;
    };
    </script>
    
    <style scoped>
    /* Scoped styles */
    </style>
    