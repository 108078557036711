  import { createRouter, createWebHistory } from 'vue-router';
  import { auth } from '@/firebase'; // Make sure this points to your Firebase config
  import { onAuthStateChanged } from 'firebase/auth';
  import HomeView from '@/views/HomeView.vue';
  import ContactView from '@/views/Contact.vue';
  import NotFound from '@/views/NotFound.vue';
  import Blogs from '@/views/Blogs.vue';
  import BlogDetail from '@/views/BlogDetails.vue';
  import SignUpPage from '@/views/SignUpPage.vue';
  import WelcomePage from '@/views/WelcomePage.vue';

  const routes = [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: { showNavbar: true, showFooter: true },
    },
    {
      path: '/welcome',
      name: 'welcome',
      component: WelcomePage,
      meta: { showNavbar: true, showFooter: false, requiresAuth: true },
    },
    {
      path: '/contact',
      name: 'contact',
      component: ContactView,
      meta: { showNavbar: true, showFooter: true },
    },
    {
      path: '/blogs',
      name: 'blogs',
      component: Blogs,
      meta: { showNavbar: true, showFooter: true },
    },
    {
      path: '/blog/:id',
      name: 'blog-detail',
      component: BlogDetail,
      props: true,
      meta: { showNavbar: true, showFooter: true },
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUpPage,
      meta: { showNavbar: false, showFooter: false },
    },
    {
      path: '/:catchAll(.*)',
      name: 'not-found',
      component: NotFound,
      meta: { showNavbar: true, showFooter: true },
    },
  ];

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

  // Route guard to check authentication
  router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is authenticated, proceed to route
          next();
        } else {
          // User is not authenticated, redirect to sign up page
          next('/signup');
        }
      });
    } else {
      next(); // Always call next() to resolve the hook
    }
  });

  export default router;
