  // store/index.js
  import { createStore } from 'vuex';
  import { auth } from '@/firebase'; // Import your Firebase auth instance
  import { onAuthStateChanged, signOut } from 'firebase/auth';

  export default createStore({
    state: {
      isDarkMode: true,
      isMusicPlaying: false,
      language: 'en',
      selectedSong: 'BobMarley',
      user: JSON.parse(localStorage.getItem('user')) || null,
    },
    mutations: {
      toggleDarkMode(state) {
        state.isDarkMode = !state.isDarkMode;
        if (state.isDarkMode) {
          document.documentElement.classList.add('dark');
          localStorage.setItem('theme', 'dark');
        } else {
          document.documentElement.classList.remove('dark');
          localStorage.setItem('theme', 'light');
        }
      },
      initializeDarkMode(state) {
        state.isDarkMode = localStorage.getItem('theme') === 'dark';
        if (state.isDarkMode) {
          document.documentElement.classList.add('dark');
        } else {
          document.documentElement.classList.remove('dark');
        }
      },
      toggleMusic(state) {
        state.isMusicPlaying = !state.isMusicPlaying;
        const audioElement = document.getElementById('background-music');
        if (audioElement) {
          if (state.isMusicPlaying) {
            audioElement.play();
          } else {
            audioElement.pause();
          }
        }
      },
      setSelectedSong(state, song) {
        state.selectedSong = song;
        const audioElement = document.getElementById('background-music');
        if (audioElement) {
          audioElement.src = require(`@/assets/music/${song}.mp3`);
          if (state.isMusicPlaying) {
            audioElement.play();
          }
        }
      },
      setLanguage(state, language) {
        state.language = language;
        localStorage.setItem('language', language);
      },
      initializeLanguage(state) {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
          state.language = savedLanguage;
        }
      },
      setUser(state, user) {
        state.user = user;
        if (user) {
          localStorage.setItem('user', JSON.stringify(user));
        } else {
          localStorage.removeItem('user');
        }
      },
      clearUser(state) {
        state.user = null;
        localStorage.removeItem('user');
      },
    },
    actions: {
      toggleDarkMode({ commit }) {
        commit('toggleDarkMode');
      },
      initializeDarkMode({ commit }) {
        commit('initializeDarkMode');
      },
      toggleMusic({ commit }) {
        commit('toggleMusic');
      },
      setSelectedSong({ commit }, song) {
        commit('setSelectedSong', song);
      },
      setLanguage({ commit }, language) {
        commit('setLanguage', language);
      },
      initializeLanguage({ commit }) {
        commit('initializeLanguage');
      },
      async initializeUser({ commit }) {
        onAuthStateChanged(auth, (user) => {
          commit('setUser', user);
        });
      },
      async logout({ commit }) {
        try {
          await signOut(auth); // Sign out from Firebase
          commit('clearUser'); // Clear user state and local storage
        } catch (error) {
          console.error('Logout failed:', error);
        }
      },
    },
  });
