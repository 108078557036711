  <template>
    <div>
      <!-- Header section -->
      <Header v-if="showNavbar" />

      <!-- Body section -->
      <div class="content">
        <audio id="background-music" :src="musicSrc" loop></audio>
        <transition name="fade-zoom" mode="out-in">
          <router-view v-slot="{ Component }">
            <component :is="Component" />
          </router-view>
        </transition>
      </div>

      <!-- Footer section -->
      <Footer v-if="showFooter" />
    </div>
  </template>

  <script setup>
  import { computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import Header from './components/Header.vue';
  import Footer from './components/Footer.vue';

  const store = useStore();
  const route = useRoute();

  // Computed property to get the selected song from Vuex store
  const musicSrc = computed(() => require(`@/assets/music/${store.state.selectedSong}.mp3`).default);

  // Computed properties to determine if the navbar and footer should be visible
  const showNavbar = computed(() => route.meta.showNavbar !== false);
  const showFooter = computed(() => route.meta.showFooter !== false);

  // Initialize music playing on component mount
  onMounted(() => {
    const audioElement = document.getElementById('background-music');
    if (audioElement && store.state.isMusicPlaying) {
      audioElement.play();
    }
  });
  </script>

  <style scoped>
  .fade-zoom-enter-active, .fade-zoom-leave-active {
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  .fade-zoom-enter-from, .fade-zoom-leave-to {
    opacity: 0;
    transform: scale(0.95);
  }

  .content {
    padding-top: 24%;
  }

  @media (min-width: 768px) {
    .content {
      padding-top: 9%; /* Adjusted padding for medium and larger screens */
    }
  }
  </style>
