    <template>
        <div class="language-selector dark:bg-gray-800 dark:text-white">
        <select v-model="selectedLanguage" @change="changeLanguage" class="dark:bg-gray-800 dark:text-white p-2 border border-gray-700 rounded">
            <option v-for="(language, key) in languages" :key="key" :value="key">
            {{ language }}
            </option>
        </select>
        </div>
    </template>
    
    <script>
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n';
    import { ref, watch, onMounted } from 'vue';
    
    export default {
        setup() {
        const store = useStore();
        const { locale } = useI18n();
    
        // Language options
        const languages = {
        en: 'English',
        de: 'German',
        sw: 'Swahili',
        fr: 'French',
        zh: 'Chinese',
        ar: 'Arabic',
        es: 'Spanish',
        hi: 'Hindi'
        };
    
        // Initialize selectedLanguage as a ref, defaulting to store state
        const selectedLanguage = ref(store.state.language);
    
        // Watch for changes in the store language state
        watch(() => store.state.language, (newLang) => {
            selectedLanguage.value = newLang;
            locale.value = newLang; // Update Vue I18n locale
        });
    
        // Function to change the language
        const changeLanguage = (event) => {
            const newLanguage = event.target.value;
            store.dispatch('setLanguage', newLanguage);
            locale.value = newLanguage; // Update Vue I18n locale
        };
    
        // Initialize language preference from local storage or default to store state
        onMounted(() => {
            const savedLanguage = localStorage.getItem('language');
            if (savedLanguage) {
            store.dispatch('setLanguage', savedLanguage);
            locale.value = savedLanguage; // Update Vue I18n locale
            } else {
            locale.value = store.state.language; // Use default language from store
            }
        });
    
        return {
            selectedLanguage,
            changeLanguage,
            languages,
        };
        },
    };
    </script>
    
    <style scoped>
    .language-selector select {
        padding: 0.5rem;
        border: 1px solid #161212;
        border-radius: 4px;
    }
    </style>
    