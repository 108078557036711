
    import { initializeApp } from 'firebase/app';
    import { getFirestore } from 'firebase/firestore';
    import { getAuth } from 'firebase/auth';

    
    const firebaseConfig = {
    apiKey: "AIzaSyBiH1NPb8jQZbEzmzq2iAsZAG__ITvD_uk",
    authDomain: "yellowjovic.firebaseapp.com",
    projectId: "yellowjovic",
    storageBucket: "yellowjovic.appspot.com",
    messagingSenderId: "1012598759704",
    appId: "1:1012598759704:web:484d6dc66657fc0883be6d"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const auth = getAuth(app);
    export { db ,auth};