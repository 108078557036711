<template>
  <div class="bg-gray-100 dark:bg-gray-800 min-h-screen">
    <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <!-- Introduction Section -->
      <section class="mb-12">
        <div class="flex items-center mb-4 profile-section">
          <div class="flex-shrink-0">
            <img src="@/assets/profile.jpg" alt="Profile Photo" class="h-24 w-24 rounded-full shadow-md profile-image">
          </div>
          <div class="ml-4">
            <!-- Split text animation for this text -->
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white heading-text" ref="headingText">{{ $t('hello_message') }}</h3>
            <p class="text-gray-800 dark:text-gray-200 leading-relaxed intro-paragraph" ref="introText">
              {{ $t('intro_text') }}
            </p>
          </div>
        </div>
      </section>

      <!-- Skills Section -->
      <section class="mb-12 skills-section">
        <h3 class="text-xl font-semibold text-gray-900 dark:text-white mb-4">{{ $t('skills_title') }}</h3>
        <ul class="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <li class="bg-white dark:bg-gray-900 shadow-md py-3 px-4 rounded-md skill-card" @mouseover="hoverIn" @mouseleave="hoverOut">
            <h4 class="text-lg font-medium text-gray-900 dark:text-white">{{ $t('front_end') }}</h4>
            <p class="text-gray-700 dark:text-gray-300">
              {{ $t('front_end_skills') }}
            </p>
          </li>
          <li class="bg-white dark:bg-gray-900 shadow-md py-3 px-4 rounded-md skill-card" @mouseover="hoverIn" @mouseleave="hoverOut">
            <h4 class="text-lg font-medium text-gray-900 dark:text-white">{{ $t('back_end') }}</h4>
            <p class="text-gray-700 dark:text-gray-300">
              {{ $t('back_end_skills') }}
            </p>
          </li>
          <li class="bg-white dark:bg-gray-900 shadow-md py-3 px-4 rounded-md skill-card" @mouseover="hoverIn" @mouseleave="hoverOut">
            <h4 class="text-lg font-medium text-gray-900 dark:text-white">{{ $t('mobile_dev') }}</h4>
            <p class="text-gray-700 dark:text-gray-300">
              {{ $t('mobile_dev_skills') }}
            </p>
          </li>
          <li class="bg-white dark:bg-gray-900 shadow-md py-3 px-4 rounded-md skill-card" @mouseover="hoverIn" @mouseleave="hoverOut">
            <h4 class="text-lg font-medium text-gray-900 dark:text-white">{{ $t('video_editing') }}</h4>
            <p class="text-gray-700 dark:text-gray-300">
              {{ $t('video_editing_skills') }}
            </p>
          </li>
        </ul>
      </section>

      <!-- Music Popup -->
      <div v-if="showMusicPopup" class="fixed inset-0 bg-gray-600 bg-opacity-75 flex items-center justify-center p-4">
        <div class="bg-white dark:bg-gray-800 p-6 rounded-md shadow-lg w-full max-w-sm md:max-w-md music-popup">
          <p class="text-gray-800 dark:text-gray-200 text-base md:text-lg mb-4 text-center md:text-left">
            {{ $t('music_prompt') }}
          </p>
          <div class="mt-2">
            <label for="song-select" class="block text-gray-800 dark:text-gray-200 mb-2">{{ $t('choose_song') }}</label>
            <div class="relative">
              <select id="song-select" v-model="selectedSong" class="w-full bg-gray-100 dark:bg-gray-700 text-gray-800 dark:text-gray-200 py-2 pl-3 pr-8 rounded-md focus:outline-none focus:ring focus:ring-blue-500">
                <option value="BobMarley">{{ $t('bob_marley') }}</option>
                <option value="DonWilliams">{{ $t('don_williams') }}</option>
                <option value="UB40">{{ $t('ub40') }}</option>
                <option value="Dolly">{{ $t('dolly_parton') }}</option>
              </select>
            </div>
          </div>
          <div class="mt-4 flex justify-between">
            <button @click="playMusic" class="bg-blue-500 text-white px-4 py-2 rounded-md">{{ $t('yes') }}</button>
            <button @click="closePopup" class="bg-gray-300 dark:bg-gray-600 text-gray-800 dark:text-gray-200 px-4 py-2 rounded-md">{{ $t('no') }}</button>
          </div>
        </div>
      </div>

      <audio id="background-music" :src="musicSrc" loop></audio>

      <!-- Old Portfolio Link -->
      <section class="mt-12">
        <h3 class="text-xl font-semibold text-gray-900 dark:text-white mb-4">{{ $t('old_me_title') }}</h3>
        <p class="text-gray-800 dark:text-gray-200 mb-4">
          {{ $t('old_me_text') }} <a href="https://biralo256.github.io/" target="_blank" class="text-blue-500 dark:text-blue-500 hover:underline">{{ $t('old_me_link') }}</a>.
        </p>
      </section>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

// Register GSAP ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const { t } = useI18n();
const showMusicPopup = ref(false); // Initially set to false
const selectedSong = ref('BobMarley'); // Default selected song
const store = useStore();

const musicSrc = computed(() => {
  if (selectedSong.value === 'BobMarley') {
    return require('@/assets/music/BobMarley.mp3');
  } else if (selectedSong.value === 'DonWilliams') {
    return require('@/assets/music/DonWilliams.mp3');
  } else if (selectedSong.value === 'Dolly') {
    return require('@/assets/music/DollyParton.mp3');
  } else if (selectedSong.value === 'UB40') {
    return require('@/assets/music/UB40.mp3');
  }
});


// Function to split text into characters
function splitText(element) {
  const text = element.innerHTML.trim();
  const splitCharacters = text.split("").map(char => {
    return `<span class="char">${char}</span>`;
  });
  element.innerHTML = splitCharacters.join("");
}

// Split text and apply GSAP animation
function animateSplitText(element, delay = 0) {
  gsap.from(element.querySelectorAll(".char"), {
    opacity: 0,
    y: 50,
    stagger: 0.05,
    duration: 1,
    ease: "power4.out",
    delay: delay
  });
}

const playMusic = () => {
  store.dispatch('toggleMusic');
  store.dispatch('setSelectedSong', selectedSong.value);
  showMusicPopup.value = false;

  // Set flag in localStorage to indicate the popup has been shown
  localStorage.setItem('musicPopupShown', 'true');

  const audio = document.getElementById('background-music');
  audio.load();
  audio.play();
};

const closePopup = () => {
  showMusicPopup.value = false;
  // Set flag in localStorage to indicate the popup has been shown
  localStorage.setItem('musicPopupShown', 'true');
};

// Hover animation for profile image with zoom, 3D spin, and glow effect
const profileImageHoverIn = () => {
  gsap.to('.profile-image', {
    scale: 1.2,
    rotationY: 20,
    boxShadow: "0px 0px 20px rgba(0, 255, 255, 0.7)",
    duration: 0.6,
    ease: "power3.out"
  });
};

const profileImageHoverOut = () => {
  gsap.to('.profile-image', {
    scale: 1,
    rotationY: 0,
    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
    duration: 0.6,
    ease: "power3.out"
  });
};



// Hover animation for skill cards
const hoverIn = (event) => {
  gsap.to(event.currentTarget, {
    scale: 1.1,
    rotationY: 10,
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
    duration: 0.5,
    ease: "power3.out"
  });
};

const hoverOut = (event) => {
  gsap.to(event.currentTarget, {
    scale: 1,
    rotationY: 0,
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    duration: 0.5,
    ease: "power3.out"
  });
};

onMounted(() => {

// Check localStorage to see if the popup has already been shown
  const popupShown = localStorage.getItem('musicPopupShown');
  if (!popupShown) {
    showMusicPopup.value = true;
  }

  const headingText = document.querySelector(".heading-text");
  const introText = document.querySelector(".intro-paragraph");

  // Split and animate the text after it's rendered
  splitText(headingText);
  splitText(introText);

  animateSplitText(headingText);
  animateSplitText(introText, 1); //Add a delay for the intro text animation
  

  // Add hover effects to profile image
const profileImage = document.querySelector('.profile-image');
  profileImage.addEventListener('mouseenter', profileImageHoverIn);
  profileImage.addEventListener('mouseleave', profileImageHoverOut);

  // Scroll-triggered 3D animation for skill cards
  gsap.from(".skill-card", {
    scrollTrigger: {
      trigger: ".skills-section",
      start: "top 80%",
      toggleActions: "play none none reverse",
    },
    opacity: 0,
    y: 50,
    rotationX: -90,
    duration: 1.5,
    stagger: 0.2,
    ease: "elastic.out(1, 0.75)",
  });

  // Mobile-specific animation
  const isMobile = window.innerWidth < 768;
  if (isMobile) {
    gsap.to(".skill-card", {
      rotationX: 0, // Remove the 3D effect for smaller screens
      scale: 1,
      ease: "power3.out"
    });
  }
});

watch(selectedSong, (newSong) => {
  store.dispatch('setSelectedSong', newSong);

  const audio = document.getElementById('background-music');
  audio.load();
  audio.play();
});
</script>

<style scoped>
body {
  font-family: 'Playwrite ES Deco', sans-serif;
}
.text-gray-900 {
  font-family: 'Playwrite ES Deco', sans-serif;
}

/* Add more depth and 3D feel to elements */
.skill-card {
  perspective: 1000px;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}
.profile-image {
  perspective: 1000px;
}
/* Hover effects for profile image */
.profile-image {
  transition: all 0.3s ease;
  backface-visibility: hidden;
}
</style>
